import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "code",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#code",
        "aria-label": "code permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Code`}</h1>
    <p><a parentName="p" {...{
        "href": "https://github.com/materia-dex/materia-contracts-proxy/blob/master/contracts/MateriaOrchestrator.sol"
      }}><inlineCode parentName="a">{`MateriaOrchestrator.sol`}</inlineCode></a></p>
    <h1 {...{
      "id": "address",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#address",
        "aria-label": "address permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Address`}</h1>
    <p><inlineCode parentName="p">{`MateriaOrchestrator`}</inlineCode>{` is deployed at `}<inlineCode parentName="p">{`0xB0F720Baa5BD1715897d4790A59f5c7aa1377D79`}</inlineCode>{` on the Ethereum `}<a parentName="p" {...{
        "href": "https://etherscan.io/address/0xB0F720Baa5BD1715897d4790A59f5c7aa1377D79"
      }}>{`mainnet`}</a>{` and the `}<a parentName="p" {...{
        "href": "https://ropsten.etherscan.io/address/0xB0F720Baa5BD1715897d4790A59f5c7aa1377D79"
      }}>{`Ropsten`}</a>{` testnet.
It was built from commit tagged as `}<inlineCode parentName="p">{`depoloy`}</inlineCode>{`.
Indeed, this contract is mainly a proxy, the real instructions to perform the various operations are inside these three operators:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`MateriaLiquidityAdder`}</inlineCode>{` is deployed at `}<inlineCode parentName="li">{`0xA85844DD423cdB2b6beA603e1768f9587d662aB8`}</inlineCode>{` on the Ethereum `}<a parentName="li" {...{
          "href": "https://etherscan.io/address/0xA85844DD423cdB2b6beA603e1768f9587d662aB8"
        }}>{`mainnet`}</a>{` and the `}<a parentName="li" {...{
          "href": "https://ropsten.etherscan.io/address/0xA85844DD423cdB2b6beA603e1768f9587d662aB8"
        }}>{`Ropsten`}</a>{` testnet. `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`MateriaLiquidityRemover`}</inlineCode>{` is deployed at `}<inlineCode parentName="li">{`0x331f3DBCC3b65B4A401a248aD7ae2A6098621a70`}</inlineCode>{` on the Ethereum `}<a parentName="li" {...{
          "href": "https://etherscan.io/address/0x331f3DBCC3b65B4A401a248aD7ae2A6098621a70"
        }}>{`mainnet`}</a>{` and the `}<a parentName="li" {...{
          "href": "https://ropsten.etherscan.io/address/0x331f3DBCC3b65B4A401a248aD7ae2A6098621a70"
        }}>{`Ropsten`}</a>{` testnet. `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`MateriaSwapper`}</inlineCode>{` is deployed at `}<inlineCode parentName="li">{`0xF644DD45c854BeD5924E95Eb0aB68E38d5BE66da`}</inlineCode>{` on the Ethereum `}<a parentName="li" {...{
          "href": "https://etherscan.io/address/0xF644DD45c854BeD5924E95Eb0aB68E38d5BE66da"
        }}>{`mainnet`}</a>{` and the `}<a parentName="li" {...{
          "href": "https://ropsten.etherscan.io/address/0xF644DD45c854BeD5924E95Eb0aB68E38d5BE66da"
        }}>{`Ropsten`}</a>{` testnet. `}</li>
    </ul>
    <p>{`This way it’s possible to update a component without redeploy all the contracts, obviously these operations must be performed by DFO with a proposal.
Another advantage is that, even if an operator is updated, the uses does not need to make a new `}<inlineCode parentName="p">{`approve`}</inlineCode>{` call.`}</p>
    <h1 {...{
      "id": "read-only-functions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#read-only-functions",
        "aria-label": "read only functions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Read-Only Functions`}</h1>
    <h2 {...{
      "id": "factory",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#factory",
        "aria-label": "factory permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`factory`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function factory() external pure returns (address);
`}</code></pre>
    <p>{`Returns `}<Link to='/docs/materia/smart-contracts/factory/#address' mdxType="Link">{`factory address`}</Link>{`.`}</p>
    <h2 {...{
      "id": "doubleproxy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#doubleproxy",
        "aria-label": "doubleproxy permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`doubleProxy`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function doubleProxy() external returns(address);
`}</code></pre>
    <p>{`Returns the DFO’s double proxy.`}</p>
    <h2 {...{
      "id": "swapper",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#swapper",
        "aria-label": "swapper permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`swapper`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function swapper() external returns(address);
`}</code></pre>
    <p>{`Returns the Swapper operator.`}</p>
    <h2 {...{
      "id": "liquidityadder",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#liquidityadder",
        "aria-label": "liquidityadder permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`liquidityAdder`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function liquidityAdder() external returns(address);
`}</code></pre>
    <p>{`Returns the liquidity adder operator.`}</p>
    <h2 {...{
      "id": "liquidityremover",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#liquidityremover",
        "aria-label": "liquidityremover permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`liquidityRemover`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function liquidityRemover() external returns(address);
`}</code></pre>
    <p>{`Returns the liquidity remover operator.`}</p>
    <h2 {...{
      "id": "erc20wrapper",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#erc20wrapper",
        "aria-label": "erc20wrapper permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`erc20Wrapper`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function erc20Wrapper() external returns(address);
`}</code></pre>
    <p>{`Returns the EthItem ERC20Wrapper.`}</p>
    <h2 {...{
      "id": "bridgetoken",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#bridgetoken",
        "aria-label": "bridgetoken permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`bridgeToken`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function bridgeToken() external returns(address);
`}</code></pre>
    <p>{`Returns WUSD address as ERC20 interoperable.`}</p>
    <h2 {...{
      "id": "ethereum_object_id",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ethereum_object_id",
        "aria-label": "ethereum_object_id permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ETHEREUM_OBJECT_ID`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function ETHEREUM_OBJECT_ID() external returns(uint);
`}</code></pre>
    <p>{`Returns IEth object id in the ERC20Wrapper collection.`}</p>
    <h1 {...{
      "id": "isethitem",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#isethitem",
        "aria-label": "isethitem permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`isEthItem`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function isEthItem(address token) external returns(address collection, bool ethItem, uint itemId);
`}</code></pre>
    <p>{`Check if token address is an EthItem, eventually returning its collection address and item id.`}</p>
    <h2 {...{
      "id": "quote",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#quote",
        "aria-label": "quote permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`quote`}</h2>
    <p>{`See `}<Link to='/docs/materia/smart-contracts/library#quote' mdxType="Link">{`quote`}</Link>{`.`}</p>
    <h2 {...{
      "id": "getamountout",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#getamountout",
        "aria-label": "getamountout permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`getAmountOut`}</h2>
    <p>{`See `}<Link to='/docs/materia/smart-contracts/library#getamountout' mdxType="Link">{`getAmountOut`}</Link>{`.`}</p>
    <h2 {...{
      "id": "getamountin",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#getamountin",
        "aria-label": "getamountin permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`getAmountIn`}</h2>
    <p>{`See `}<Link to='/docs/materia/smart-contracts/library#getamountin' mdxType="Link">{`getAmountIn`}</Link>{`.`}</p>
    <h2 {...{
      "id": "getamountsout",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#getamountsout",
        "aria-label": "getamountsout permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`getAmountsOut`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function getAmountsOut(uint amountIn, address[] memory path) public view returns (uint[] memory amounts);
`}</code></pre>
    <p>{`See `}<Link to='/docs/materia/smart-contracts/library#getamountsout' mdxType="Link">{`getAmountsOut`}</Link>{`.`}</p>
    <h2 {...{
      "id": "getamountsin",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#getamountsin",
        "aria-label": "getamountsin permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`getAmountsIn`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function getAmountsIn(uint amountOut, address[] memory path) public view returns (uint[] memory amounts);
`}</code></pre>
    <p>{`See `}<Link to='/docs/materia/smart-contracts/library#getamountsin' mdxType="Link">{`getAmountsIn`}</Link>{`.`}</p>
    <h1 {...{
      "id": "state-changing-functions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#state-changing-functions",
        "aria-label": "state changing functions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`State-Changing Functions`}</h1>
    <h2 {...{
      "id": "setdoubleproxy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#setdoubleproxy",
        "aria-label": "setdoubleproxy permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`setDoubleProxy`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function setDoubleProxy(address doubleProxy) external;
`}</code></pre>
    <p>{`Set the DFO’s double proxy.`}</p>
    <h2 {...{
      "id": "swapper-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#swapper-1",
        "aria-label": "swapper 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`swapper`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function setSwapper(address swapper) external;
`}</code></pre>
    <p>{`Set the Swapper operator.`}</p>
    <h2 {...{
      "id": "liquidityadder-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#liquidityadder-1",
        "aria-label": "liquidityadder 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`liquidityAdder`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function liquidityAdder(address liquidityAdder) external;
`}</code></pre>
    <p>{`Set the liquidity adder operator.`}</p>
    <h2 {...{
      "id": "liquidityremover-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#liquidityremover-1",
        "aria-label": "liquidityremover 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`liquidityRemover`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function setLiquidityRemover(address liquidityRemover) external;
`}</code></pre>
    <p>{`Set the liquidity remover operator.`}</p>
    <h2 {...{
      "id": "seterc20wrapper",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#seterc20wrapper",
        "aria-label": "seterc20wrapper permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`setErc20Wrapper`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function setErc20Wrapper(address erc20Wrapper) external;
`}</code></pre>
    <p>{`Set the EthItem ERC20Wrapper.`}</p>
    <h2 {...{
      "id": "bridgetoken-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#bridgetoken-1",
        "aria-label": "bridgetoken 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`bridgeToken`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function setBridgeToken(address bridgeToken) external;
`}</code></pre>
    <p>{`Set WUSD address as ERC20 interoperable.`}</p>
    <h2 {...{
      "id": "ethereum_object_id-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ethereum_object_id-1",
        "aria-label": "ethereum_object_id 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ETHEREUM_OBJECT_ID`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function setEthereumObjectId(uint id) external;
`}</code></pre>
    <h2 {...{
      "id": "addliquidity",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#addliquidity",
        "aria-label": "addliquidity permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`addLiquidity`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function addLiquidity(
    address token,
    uint256 tokenAmountDesired,
    uint256 bridgeAmountDesired,
    uint256 tokenAmountMin,
    uint256 bridgeAmountMin,
    address to,
    uint256 deadline
) external returns (uint amountToken, uint amountETH, uint liquidity);
`}</code></pre>
    <p>{`Add liquidity to a WUSD-token pair, wrapping if necessary. `}</p>
    <h2 {...{
      "id": "addliquidityeth",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#addliquidityeth",
        "aria-label": "addliquidityeth permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`addLiquidityETH`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function addLiquidityETH(
    uint256 bridgeAmountDesired,
    uint256 EthAmountMin,
    uint256 bridgeAmountMin,
    address to,
    uint256 deadline
) external payable;
`}</code></pre>
    <p>{`Add liquidity to a WUSD-IETH pair, wrapping ETH into IETH. `}</p>
    <h2 {...{
      "id": "removeliquidity",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#removeliquidity",
        "aria-label": "removeliquidity permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`removeLiquidity`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function removeLiquidity(
    address token,
    uint256 liquidity,
    uint256 tokenAmountMin,
    uint256 bridgeAmountMin,
    address to,
    uint256 deadline
) external;
`}</code></pre>
    <p>{`Burn the specific MPs getting back the assets underlying the pair.`}</p>
    <h2 {...{
      "id": "removeliquidityeth",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#removeliquidityeth",
        "aria-label": "removeliquidityeth permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`removeLiquidityETH`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function removeLiquidityETH(
    uint256 liquidity,
    uint256 bridgeAmountMin,
    uint256 EthAmountMin,
    address to,
    uint256 deadline
) external;
`}</code></pre>
    <p>{`Burn the MP getting back the tokens underlying the pair, automatically unwrap IETH into ETH.`}</p>
    <h2 {...{
      "id": "removeliquiditywithpermit",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#removeliquiditywithpermit",
        "aria-label": "removeliquiditywithpermit permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`removeLiquidityWithPermit`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function removeLiquidityWithPermit(
    address token,
    uint256 liquidity,
    uint256 tokenAmountMin,
    uint256 bridgeAmountMin,
    address to,
    uint256 deadline,
    bool approveMax,
    uint8 v,
    bytes32 r,
    bytes32 s
) external;
`}</code></pre>
    <p>{`Same as `}<inlineCode parentName="p">{`removeLiquidity`}</inlineCode>{` but does not require a preceding `}<inlineCode parentName="p">{`approve`}</inlineCode>{` since it uses the `}<inlineCode parentName="p">{`permit`}</inlineCode>{` functionality of the MPs.`}</p>
    <h2 {...{
      "id": "removeliquidityethwithpermit",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#removeliquidityethwithpermit",
        "aria-label": "removeliquidityethwithpermit permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`removeLiquidityETHWithPermit`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function removeLiquidityETHWithPermit(
    uint256 liquidity,
    uint256 tokenAmountMin,
    uint256 bridgeAmountMin,
    address to,
    uint256 deadline,
    bool approveMax,
    uint8 v,
    bytes32 r,
    bytes32 s
) external;
`}</code></pre>
    <p>{`Same as `}<inlineCode parentName="p">{`removeLiquidityETH`}</inlineCode>{` but does not require a preceding `}<inlineCode parentName="p">{`approve`}</inlineCode>{` since it uses the `}<inlineCode parentName="p">{`permit`}</inlineCode>{` functionality of the MPs.`}</p>
    <h2 {...{
      "id": "swapexacttokensfortokens",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#swapexacttokensfortokens",
        "aria-label": "swapexacttokensfortokens permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`swapExactTokensForTokens`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function swapExactTokensForTokens(
    uint256 amountIn,
    uint256 amountOutMin,
    address[] memory path,
    address to,
    uint256 deadline
) external returns (uint256[] memory amounts)
`}</code></pre>
    <p>{`Swap an exact amount of tokens for a minimum amount of tokens, automatically manages the wrap/unwrap.`}</p>
    <h2 {...{
      "id": "swaptokensforexacttokens",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#swaptokensforexacttokens",
        "aria-label": "swaptokensforexacttokens permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`swapTokensForExactTokens`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function swapTokensForExactTokens(
    uint256 amountOut,
    uint256 amountInMax,
    address[] memory path,
    address to,
    uint256 deadline
) external returns (uint256[] memory amounts)
`}</code></pre>
    <p>{`Swap an amount (specifying the maximum) of tokens for an exact amount of tokens, automatically manages the wrap/unwrap.`}</p>
    <h2 {...{
      "id": "swapexactethfortokens",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#swapexactethfortokens",
        "aria-label": "swapexactethfortokens permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`swapExactETHForTokens`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`    function swapExactETHForTokens(
        uint256 amountOutMin,
        address[] memory path,
        address to,
        uint256 deadline
) external returns (uint256[] memory amounts)
`}</code></pre>
    <p>{`Swap an exact amount of ETH for a minimum amount of tokens, automatically manages the wrap/unwrap.`}</p>
    <h2 {...{
      "id": "swaptokensforexacteth",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#swaptokensforexacteth",
        "aria-label": "swaptokensforexacteth permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`swapTokensForExactETH`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function swapTokensForExactETH(
    uint256 amountOut,
    uint256 amountInMax,
    address[] memory path,
    address to,
    uint256 deadline
) external returns (uint256[] memory amounts)
`}</code></pre>
    <p>{`Swap an amount (specifying the maximum) of tokens for an exact amount of ETH, automatically manages the wrap/unwrap.`}</p>
    <h2 {...{
      "id": "swapexacttokensfortokens-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#swapexacttokensfortokens-1",
        "aria-label": "swapexacttokensfortokens 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`swapExactTokensForTokens`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function swapExactTokensForETH(
    uint256 amountIn,
    uint256 amountOutMin,
    address[] memory path,
    address to,
    uint256 deadline
) external returns (uint256[] memory amounts)
`}</code></pre>
    <p>{`Swap an exact amount of tokens for a minimum amount of ETH, automatically manages the wrap/unwrap.`}</p>
    <h2 {...{
      "id": "swapethforexacttokens",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#swapethforexacttokens",
        "aria-label": "swapethforexacttokens permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`swapETHForExactTokens`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function swapETHForExactTokens(
    uint256 amountOut,
    address[] memory path,
    address to,
    uint256 deadline
) external returns (uint256[] memory amounts)
`}</code></pre>
    <p>{`Swap an amount (specifying the maximum) of ETH for an exact amount of tokens, automatically manages the wrap/unwrap.`}</p>
    <h1 {...{
      "id": "as-erc1155-receiver",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#as-erc1155-receiver",
        "aria-label": "as erc1155 receiver permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`As ERC1155 receiver`}</h1>
    <p>{`Moreover the Materia orchestrator behaves as an ERC1155 receiver, this means that if you send an ERC1155 token to it specifying a payload you can trigger a callback function.
This is used to avoid unnecessary `}<inlineCode parentName="p">{`approve`}</inlineCode>{` when managing items.`}</p>
    <p>{`Currently there are 5 possible operations identified specific ids and payloads:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`1`}</inlineCode>{` is for adding liquidity (item/wusd)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`2`}</inlineCode>{` is for swapping an exact amount of items for tokens`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`3`}</inlineCode>{` is for swapping items for an exact amount of tokens`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`4`}</inlineCode>{` is for swapping an exact amount of items for ETH`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`5`}</inlineCode>{` is for swapping items for an exact amount of ETH`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`import '@materia/materia-contracts-proxy/contracts/interfaces/IMateriaOrchestrator.sol';
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`interface IMateriaOrchestrator is IERC1155Receiver {
    function setDoubleProxy(address newDoubleProxy) external;

    function setBridgeToken(address newBridgeToken) external;

    function setErc20Wrapper(address newErc20Wrapper) external;

    function setFactory(address newFactory) external;

    function setEthereumObjectId(uint256 newEthereumObjectId) external;

    function setSwapper(address _swapper) external;

    function setLiquidityAdder(address _adder) external;

    function setLiquidityRemover(address _remover) external;

    function retire(address newOrchestrator) external;

    function setFees(
        address token,
        uint256 materiaFee,
        uint256 swapFee
    ) external;

    function setDefaultFees(uint256 materiaFee, uint256 swapFee) external;

    function setFeeTo(address feeTo) external;

    function getCrumbs(
        address token,
        uint256 amount,
        address receiver
    ) external;

    function factory() external view returns (IMateriaFactory);

    function bridgeToken() external view returns (IERC20);

    function erc20Wrapper() external view returns (IERC20WrapperV1);

    function ETHEREUM_OBJECT_ID() external view returns (uint256);

    function swapper() external view returns (address);

    function liquidityAdder() external view returns (address);

    function liquidityRemover() external view returns (address);

    function doubleProxy() external view returns (IDoubleProxy);

    //Liquidity adding

    function addLiquidity(
        address token,
        uint256 tokenAmountDesired,
        uint256 bridgeAmountDesired,
        uint256 tokenAmountMin,
        uint256 bridgeAmountMin,
        address to,
        uint256 deadline
    )
        external
        returns (
            uint256 amountA,
            uint256 amountB,
            uint256 liquidity
        );

    function addLiquidityETH(
        uint256 bridgeAmountDesired,
        uint256 EthAmountMin,
        uint256 bridgeAmountMin,
        address to,
        uint256 deadline
    )
        external
        payable
        returns (
            uint256 amountToken,
            uint256 amountETH,
            uint256 liquidity
        );

    //Liquidity removing

    function removeLiquidity(
        address token,
        uint256 liquidity,
        uint256 tokenAmountMin,
        uint256 bridgeAmountMin,
        address to,
        uint256 deadline
    ) external;

    function removeLiquidityETH(
        uint256 liquidity,
        uint256 bridgeAmountMin,
        uint256 EthAmountMin,
        address to,
        uint256 deadline
    ) external;

    function removeLiquidityWithPermit(
        address token,
        uint256 liquidity,
        uint256 tokenAmountMin,
        uint256 bridgeAmountMin,
        address to,
        uint256 deadline,
        bool approveMax,
        uint8 v,
        bytes32 r,
        bytes32 s
    ) external;

    function removeLiquidityETHWithPermit(
        uint256 liquidity,
        uint256 ethAmountMin,
        uint256 bridgeAmountMin,
        address to,
        uint256 deadline,
        bool approveMax,
        uint8 v,
        bytes32 r,
        bytes32 s
    ) external;

    //Swapping

    function swapExactTokensForTokens(
        uint256 amountIn,
        uint256 amountOutMin,
        address[] memory path,
        address to,
        uint256 deadline
    ) external returns (uint256[] memory amounts);

    function swapTokensForExactTokens(
        uint256 amountOut,
        uint256 amountInMax,
        address[] memory path,
        address to,
        uint256 deadline
    ) external returns (uint256[] memory amounts);

    function swapExactETHForTokens(
        uint256 amountOutMin,
        address[] memory path,
        address to,
        uint256 deadline
    ) external payable;

    function swapTokensForExactETH(
        uint256 amountOut,
        uint256 amountInMax,
        address[] memory path,
        address to,
        uint256 deadline
    ) external;

    function swapExactTokensForETH(
        uint256 amountIn,
        uint256 amountOutMin,
        address[] memory path,
        address to,
        uint256 deadline
    ) external;

    function swapETHForExactTokens(
        uint256 amountOut,
        address[] memory path,
        address to,
        uint256 deadline
    ) external payable;

    //Materia utilities

    function isEthItem(address token)
        external
        view
        returns (
            address collection,
            bool ethItem,
            uint256 itemId
        );

    function quote(
        uint256 amountA,
        uint256 reserveA,
        uint256 reserveB
    ) external pure returns (uint256 amountB);

    function getAmountOut(
        uint256 amountIn,
        uint256 reserveIn,
        uint256 reserveOut
    ) external pure returns (uint256 amountOut);

    function getAmountIn(
        uint256 amountOut,
        uint256 reserveIn,
        uint256 reserveOut
    ) external pure returns (uint256 amountIn);

    function getAmountsOut(uint256 amountIn, address[] memory path) external view returns (uint256[] memory amounts);

    function getAmountsIn(uint256 amountOut, address[] memory path) external view returns (uint256[] memory amounts);
}
`}</code></pre>
    <h1 {...{
      "id": "abi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#abi",
        "aria-label": "abi permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ABI`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import IMateriaOrchestrator from '@materia/materia-contracts-proxy/build/IMateriaOrchestrator.json'
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      